.chatBotWrapper {
  position: relative;
  padding: 0.01rem;

  .chatBotComingSoon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    backdrop-filter: blur(1.3px);

    h4 {
      font-size: 1.4rem;
      margin-top: 0.8rem;
    }
  }

  .chatBotButtons {
    margin: 1rem 0 0;
    padding-bottom: 0.5rem;
    opacity: 0.35;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;

    button {
      border-radius: 24px;
      border: 0.5px solid rgba(255, 255, 255, 0.7);
      padding: 0.2rem 0.9rem;
      text-transform: none;
      margin-right: 0.3rem;
      font-weight: 100;
      font-size: 0.7rem;
      margin-bottom: 5px;
      white-space: nowrap;
      display: inline-block;
      min-width: auto !important;
    }
  }

  .chatBotBox {
    height: 100px;
    border-radius: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    opacity: 0.35;

    p {
      font-weight: 100;
      font-size: 0.75rem;
      padding: 0.5rem 2.5rem 0 0.5rem;
    }

    .chatBotSendButton {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
