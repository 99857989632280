.share {
  a {
    width: 60px;
    height: 60px;
    margin: 0 0.3rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem !important;
    border-radius: 20px !important;
    background-color: #fff;

    img {
      width: 42px !important;
      transition: all 0.3s;
    }

    &:hover {
      background-color: #fff;

      img {
        transform: scale(1.1);
      }
    }
  }
}
