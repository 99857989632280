.cryptoSignalHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: auto;
  margin-top: 1.5rem;
  position: relative;
  transition: all 0.3s;

  .cryptoSignalLoadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 100%;
    z-index: 100;
  }
}

.coinSelectorWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 26px;
    margin-right: 0.5rem;
  }
}

.tabSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tabItem {
    display: flex;
    align-items: center;
    padding: 3px 13px;
    font-size: 0.8rem;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 0.5rem;
    transition: all 0.3s;
  }
}

.coinsListWrapper {
  position: absolute !important;
  left: 0%;
  top: 120%;
  width: 100%;
  padding: 0rem;
  padding-top: 1rem;
}

.coinsListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0rem;
  cursor: pointer;

  div {
    flex: 1;
    padding-bottom: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
    border-bottom: 1px solid #999;

    h6 {
      line-height: 1;
      font-weight: 300;
      font-size: 0.95rem;
    }

    span {
      font-weight: 100;
      font-size: 0.75rem;
    }
  }
}

.selectedCoin {
  background-color: red;
}
