/* Tweet Card */

.newsTweetCardWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  overflow-x: hidden;

  .newsCardWrapper {
    padding: 0 !important;
    display: flex;

    .tweetImage {
      width: 70px;
      height: 70px;
      border-radius: 15px;
    }
  }

  .newsCardTitle {
    display: flex;
    align-items: center;

    h6 {
      font-weight: 500;
      margin-right: 8px;
    }

    span {
      font-weight: 100;
    }
  }

  p {
    font-weight: 100;
    margin-top: 5px;
    font-size: 0.7rem;
    display: block;
    padding-right: 10px;
  }

  .newsCardExtraButton {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .newsTweetCardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0.5rem 0 0;
    width: 80%;

    .newsCardInfo {
      display: flex;

      p {
        display: flex;
        align-items: center;
        font-size: 0.6rem;
        font-weight: 100;
        margin: 0;

        svg {
          margin-right: 3px;
        }
      }
    }

    span {
      font-weight: 100;
      font-size: 0.6rem;
    }

    div {
      flex: 1;

      a {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        margin-right: 0.2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

/* Rss Card */

.newsRssCardWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  overflow-x: hidden;
  width: 100%;

  .newsCardWrapper {
    padding: 0 !important;
    display: flex;
    width: 100%;
  }

  .newsCardTitle {
    flex: 1;
    display: flex;
    align-items: flex-start;

    h6 {
      font-weight: 500;
      margin-right: 8px;
    }

    span {
      font-weight: 100;
    }
  }

  p {
    font-weight: 100;
    margin-top: 5px;
    font-size: 0.7rem;
    display: block;
    padding-right: 10px;
  }

  .newsRssCardFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0.5rem;

    span {
      font-weight: 100;
      font-size: 0.6rem;
    }
  }
}
