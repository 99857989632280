.primary_button {
  font-weight: 500;
  border-radius: 23px;
  text-decoration: none;
  padding: 14px 65px !important;
  text-transform: none !important;
  border-radius: 23px !important;
  transition: all 0.3s;
  z-index: 100;
  box-shadow: 10px -6px 58px 14px rgba(0, 148, 115, 0.5) !important;
  white-space: nowrap;
}

.default_button {
  text-transform: none !important;
  border-radius: 30px !important;
  font-weight: 300 !important;
  margin-top: 20px;
  background-color: #fff !important;
  color: #272525 !important;
}

.secondary_button {
  text-transform: none !important;
  border-radius: 30px !important;
  font-weight: 300 !important;
  max-width: 300px !important;
  display: block !important;
  width: 100% !important;
  margin: auto !important;
  margin-top: 20px !important;
}
