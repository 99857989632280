.accountDangerSection {
  margin-top: 2rem;
  border-radius: 15px;
  padding: 1rem;

  h6 {
    font-size: 1.05rem;
    font-weight: 500;
    border-bottom: 1px solid #fa637a;
    color: #fa637a;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
  }

  p {
    font-weight: 100;
    line-height: 1.6;
  }

  button {
    margin-top: 1.5rem;
    text-transform: none !important;
    border-radius: 30px !important;
    font-weight: 300 !important;
  }
}
