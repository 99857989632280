.sentimentGaugeWrapper {
  width: 100%;
  max-width: 300px;
  margin: auto;
  position: relative;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .sentimentNotBuyText {
    position: absolute;
    left: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-left: 30px;
    text-align: left;
  }

  .sentimentBuyText {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-right: 40px;
    text-align: right;
  }

  .sentimentBotText {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-right: 40px;
    text-align: right;
  }

  .sentimentNotBotText {
    position: absolute;
    left: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-left: 30px;
    text-align: left;
  }

  .sentimentValue {
    position: absolute;
    right: 0;
    bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
}

.statisticsGaugeWrapper {
  width: 100%;
  max-width: 130px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: -35px;

  .statisticsValue {
    position: absolute;
    right: 0;
    bottom: -27px;
    font-size: 9.3px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
}
