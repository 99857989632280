.settingsWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.settingsContent {
  flex: 1;

  h1 {
    margin: 1rem auto 3rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: 300;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
}

@media (min-width: "1000px") {
  .settingsWrapper {
    max-width: 60%;
    margin: auto;
  }
}
