.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2001;
}

.footerWrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  .bottomNavigation {
    display: flex;
    justify-content: center;
    height: 70px;
    padding-top: 0.1rem;
    background-color: transparent;
  }

  .bottomNavigationAction {
    flex: none;
    margin: 0 12px;
    width: 72px;
    min-width: auto;
    border-radius: 40px 40px 0 0;
    padding: 6px 15px 15px;
    position: relative;
  }
}

.themeSwitcher {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    svg {
      padding: 0.4rem;
      font-size: 2.2rem;
      margin-left: 0.2rem;
      border-radius: 13px;
      cursor: pointer;
    }
  }
}
