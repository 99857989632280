.linechart_tooltip {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
  border: none;
  box-shadow: none;

  h6 {
    font-weight: 100;
    font-size: 0.7rem;
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.4rem;
    width: 100%;

    h4,
    h5 {
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
}
