.cryptoInfoWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  margin: 3rem auto;

  p {
    font-size: 0.77rem;
    font-weight: 100;
  }

  h5 {
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
    margin-top: 0.2rem;
  }
}
