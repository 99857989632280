.progressbarWrapper {
  position: relative;
  margin-bottom: 1rem;

  .progressbarInfo {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      margin: 0 0.5rem;

      span {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
      }

      h6 {
        line-height: 1;
        font-weight: 100;
      }
    }
  }

  .progressbar {
    width: 70%;
    height: 22px;
    max-width: 300px;
    border-radius: 30px;
    color: white;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 1rem auto;

    .bar_1,
    .bar_2,
    .bar_3 {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-sizing: border-box;
      position: relative;
      overflow: visible;

      span {
        font-size: 0.6rem;
        font-weight: 100;
        position: absolute;
      }
    }
  }
}
