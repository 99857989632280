.newsCard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  overflow-x: hidden;

  .newsCardWrapper {
    padding: 0 !important;
    display: flex;
  }

  .newsCardTitle {
    display: flex;
    align-items: center;

    h6 {
      font-weight: 500;
      margin-right: 8px;
    }

    span {
      font-weight: 100;
    }
  }

  p {
    font-weight: 100;
    margin-top: 5px;
    font-size: 0.8rem;
    display: block;
    padding-right: 20px;
  }

  .newsCardExtraButton {
    position: absolute;
    top: 24px;
    right: -2px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .newsCardInfo {
    display: flex;
    padding-left: 3rem;
    margin-top: 0.1rem;

    p {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 100;

      svg {
        margin-right: 3px;
      }
    }
  }

  .newsCardMedia img {
    margin-left: 1rem;
  }
}

@media (min-width: 700px) {
  .newsCardMedia img {
    margin-left: 3rem !important;
  }
}
