.influencersPage {
  position: relative;

  ul {
    flex-direction: row;
  }

  .influencersFilter {
    position: absolute;
    top: -5px;
    right: 0;
  }
}

.influencersWrapper {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 0.9rem;
  row-gap: 1.3rem;
}

@media (min-width: "900px") {
  .influencersWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem;
    margin-top: 3rem;
  }
}
