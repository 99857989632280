.bottomDrawerWrapper {
  position: fixed !important;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 60px 60px 0 0;
  padding: 2rem;
  transition: all 0.5s;
  min-height: fit-content;
  padding-bottom: 8rem;

  h6 {
    font-size: 1.2rem !important;
    font-weight: 500;
  }

  p {
    font-size: 0.86rem !important;
    font-weight: 100;
    line-height: 1.5;
  }
}
