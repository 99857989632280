.moversMarquee {
  width: 100%;
  margin-bottom: 0.8rem;

  .moverItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: -2px;

    img {
      width: 28px;
      height: 28px;
      padding: 0.02rem;
      border-radius: 50%;
      object-fit: contain;
      margin-right: 0.4rem;
    }

    .moverItemContent {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 0.65rem;
        font-weight: 400;
      }

      h4 {
        font-size: 0.56rem;
        margin-right: 0.8rem;
        font-weight: 100;
      }

      span {
        font-size: 0.56rem;
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }
}
