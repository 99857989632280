@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.otf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.otf");
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins";
}

#chartContainer .apexcharts-tooltip {
  color: #000000;
}

a {
  text-decoration: none;
}

.breadCrumb {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.breadCrumb a,
p {
  font-size: 0.8rem;
  line-height: 1;
}

.glass-background {
  backdrop-filter: saturate(180%) blur(10px);
}

.default-card-style {
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 26px;
}

.strong-glass-background {
  backdrop-filter: saturate(180%) blur(15px);
  background-color: rgba(0, 0, 0, 0.3);
}

.glass-dark-background {
  backdrop-filter: saturate(180%) blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.top-zindex {
  position: relative;
  z-index: 2002;
}

.infinite-scroll-component {
  overflow-x: visible !important;
}

#influencerChart .apexcharts-tooltip {
  color: #0a0e0d;
}

/* input:-internal-autofill-selected {
  background-color: red !important;
} */

.pattern_1 {
  width: 235px;
  height: 235px;
  position: absolute;
  z-index: 0 !important;
  filter: blur(50px);
  border-radius: 50%;
  background: rgba(0, 148, 115, 0.3);
  transition: all 0.9s;
}

.pattern_2 {
  width: 235px;
  height: 235px;
  background: rgba(0, 148, 115, 0.3);
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0 !important;
  transition: all 0.9s;
}

.pattern_3 {
  width: 90px;
  position: absolute;
  filter: blur(4px);
  z-index: 0;
  transition: all 0.9s;
}

.pattern_4,
.pattern_5 {
  width: 20px;
  height: 20px;
  background: #00d9c7;
  position: absolute;
  border-radius: 50%;
  filter: blur(2px);
  z-index: 0 !important;
  transition: all 0.9s;
}

.pattern_6 {
  width: 120px;
  height: 60px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 2px solid #00d9c7;
  border-bottom: 0;
  filter: blur(2px);
  position: absolute;
  z-index: 0 !important;
  transition: all 0.9s;
}

.pattern_1_auth {
  width: 235px;
  height: 235px;
  position: absolute;
  z-index: 0;
  filter: blur(50px);
  border-radius: 50%;
  background: rgba(0, 148, 115, 0.3);
  animation: pattern_1_animation_auth 9s linear infinite;
}

.pattern_2_auth {
  width: 235px;
  height: 235px;
  background: rgba(0, 148, 115, 0.3);
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0;
  animation: pattern_2_animation_auth 5s ease infinite;
}

.pattern_3_auth {
  width: 235px;
  height: 235px;
  background: rgba(67, 150, 126, 0.26);
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0;
  animation: pattern_3_animation_auth 5s linear infinite;
}

.pattern_4_auth {
  width: 344px;
  height: 69px;
  position: absolute;
  z-index: 0;
  top: 26px;
  left: -53px;
  filter: blur(5px);
  border-radius: 50%;
  transform: rotate(-10deg);
  border: 1px solid rgba(8, 251, 197, 0.5);
}

.sideShape_1_animation {
  animation: opacityAnimation 0.5s ease-out 2.5s;
}

.sideShape_2_animation {
  animation: opacityAnimation 3s ease-out 1s;
}

.sideShape_3_animation {
  animation: opacityAnimation 0.5s ease-out 1.5s;
}

.modalActions {
  margin-top: 1.5rem;
}

.modalActions button:first-of-type {
  text-transform: none;
  border-radius: 30px;
  padding: 0.3rem 1.2rem;
}

.modalActions button:last-of-type {
  text-transform: none;
  border-radius: 30px;
  margin-left: 0.5rem;
  padding: 0.3rem 1.2rem;
}

.smallAreaChartContainer text {
  display: none !important;
}

.smallAreaChartContainer .apexcharts-inner {
  max-height: 45px;
  height: 45px;
  transform: translate(0, 0);
}

.smallAreaChartContainer line {
  display: none;
}

.newsCardWrapper .Mui-expanded {
  margin: 0 !important;
}

.react-select-container {
  /* background-color: red; */
  position: relative;
  z-index: 1000;
}

.marketcap_treemap .apexcharts-data-labels text {
  font-weight: 100 !important;
}

@keyframes pattern_1_animation_auth {
  0% {
    top: 26px;
    left: 85%;
  }

  50% {
    top: 150px;
    left: -77px;
  }

  100% {
    top: 26px;
    left: 85%;
  }
}

@keyframes pattern_2_animation_auth {
  0% {
    top: 275px;
    right: -130px;
  }

  50% {
    top: 85%;
    right: -130px;
  }

  100% {
    top: 275px;
    right: -130px;
  }
}

@keyframes pattern_3_animation_auth {
  0% {
    top: 594px;
    left: -92px;
  }

  50% {
    top: 50px;
    left: 85%;
  }

  100% {
    top: 594px;
    left: -92px;
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
