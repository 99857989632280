.transactionCard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  padding: 20px;
  border-radius: 26px;

  .transactionCardWrapper {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .transactionCardTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      h2 {
        font-weight: 300;
      }

      span {
        font-weight: 100;
        font-size: 0.68rem;
      }

      h3 {
        font-weight: 100;
        font-size: 0.8rem;
      }

      h4 {
        font-weight: 100;
        font-size: 0.85rem;
      }
    }
  }

  p {
    font-weight: 100;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 0.8rem;
    display: block;
  }

  .transactionCardExtraButton {
    position: absolute;
    top: 10px;
    right: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
