.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  margin: 1.5rem auto;
  transform: rotate(0);
  animation: loadingAnimation 5s ease-in-out infinite;

  img {
    width: 60px;
  }
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
