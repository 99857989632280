.assetsWrapper {
  h2 {
    font-size: 0.75rem;
    font-weight: 500;
  }

  h3 {
    font-size: 0.7rem;
    font-weight: 100;
  }

  h6 {
    font-size: 0.75rem;
  }

  tr {
    border-bottom: 1px solid #535353;
  }

  th {
    font-weight: 100;
    height: auto;
    min-height: 60px;
    border-bottom: none;
  }

  .coinsTopInfluencers {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      margin: 0 0.03rem;
    }
  }

  .coinLogo {
    width: 32px;
    height: 32px;
    margin-right: 7px;
    margin-left: 3px;
    border-radius: 50%;
  }

  .cryptoCardChart {
    width: 160px;
    height: 90px;
    position: absolute;
    left: 0px;
    top: 0;

    div {
      &:first-of-type {
        min-height: 90px !important;
      }
    }
  }
}

@media (min-width: 600px) {
  .assetsWrapper {
    ::-webkit-scrollbar {
      width: 0px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #777;
    }
  }
}
